<template>
    <div>
        <Breadcrumb titulo="PERFIL CAMPANHAS" :items="itensBreacrumb" backgroundColor="#259cd3" />
        <div id="container_campanhas" class="container_campanhas">

            <div class="row" style="flex-direction: row-reverse;">
                <div class="col-sm">
                    <botao-padrao @click="abrirModalFormularioPerfil()" texto="Novo perfil" class="btn-ajustes btn_mod_campanhas" />
                </div>
            </div>

            <b-table :items="listaPerfil"
                     :fields="fields"
                     selected-variant=""
                     ref="selectableTable"
                     responsive
                     striped
                     hover
                     class="tabela-padrao-btable app-scroll-custom">

                <template #cell(CodigoExterno)="{ item }">
                    {{ !!item.CodigoExterno ? 'Sim' : 'Não' }}
                </template>

                <template #cell(acoes)="row">
                    <botao-tab-edit @click="abrirModalFormularioPerfil(row.item)" />
                    <botao-tab-remove @click="removerPerfil(row.item)" />
                </template>

            </b-table>
        </div>

        <ModalPerfilCampanha 
            ref="modal_perfil_campanha"
            :perfil="perfil"
            @salvo="buscarPerfis" 
        />

    </div>

</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import ModalPerfilCampanha from '@/components/campanhas/ModalPerfilCampanha.vue'
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
    import BotaoTabEdit from '@/components/botoes/BotaoTabEdit.vue'
    import BotaoTabRemove from '@/components/botoes/BotaoTabRemove.vue'
    import CampanhaServices from '@/assets/js/services/CampanhasServices'

    export default {
        name: 'PerfilCampanhaPage',
        components: {
            Breadcrumb,
            ModalPerfilCampanha,
            BotaoPadrao,
            BotaoTabEdit,
            BotaoTabRemove
        },

        data: function () {
            return {
                fields: [
                    { key: 'Titulo', label: 'Título', sortable: true },
                    { key: 'CodigoExterno', label: 'Filtros', sortable: true },
                    { key: 'InclusaoFormatada', label: 'Inclusão', sortable: true, sortDirection: 'asc' },
                    { key: 'acoes', label: 'Ações', class: 'text-center', thStyle: 'width: 10rem' }
                ],
                itensBreacrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos',
                        cor: '#259cd3'
                    },
                    {
                        id: '2',
                        classe: 'fa fa-bullhorn',
                        texto: 'Campanhas',
                        link: '/campanhas',
                        cor: '#259cd3'
                    },
                    {
                        id: '3',
                        classe: 'fa fa-envelope-o',
                        texto: 'Perfil de Campanha',
                        link: '/perfil-campanhas',
                        cor: '#259cd3'
                    },
                ],

                listaPerfil: [],
                perfil: {},
            }
        },

        methods: {
            async buscarPerfis() {
                this.listaPerfil = await CampanhaServices.buscarPerfisFiltrosCampanhas();
            },
            abrirModalFormularioPerfil(perfilCampanha = null) {
                this.perfil = perfilCampanha;

                setTimeout(() => {
                    this.$refs.modal_perfil_campanha.show() 
                }, this.perfil ? 300 : 0);
            },
            async removerPerfil(perfil) {
                if (!confirm(`Confirma a exclusão do perfil "${perfil.Titulo}"?`)) {
                    return;
                }

                $("#caixa_carregamento").show()

                await CampanhaServices.removerPerfilCampanha(perfil.Id);

                await this.buscarPerfis();

                $("#caixa_carregamento").hide()
            },
        },

        mounted() {
            this.buscarPerfis()
        },
    }
</script>

<style scoped>
    #container_campanhas {
        padding: 10px;
        background-color: #f3f3f3;
        margin: 12px 8px;
    }

    .btn-ajustes {
        width: 180px;
        background-color: var(--cinza-2);
    }

    .btn_mod_campanhas {
        margin-left: 2%;
        background-color: var(--cor-primaria-cliente);
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        padding: 10px;
        float: right;
        text-align: center;
    }

        .btn_mod_campanhas:hover {
            background-color: #9492a7;
        }

    .tabela-padrao-btable {
        background-color: #fff;
        height: calc(100vh - 200px);
        overflow-y: auto;
        margin-bottom: 0;
        font-size: 12px;
        padding: 0 7px 7px 7px;
        margin: 8px 0
    }
</style>